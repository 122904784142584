<template>
    <div class="ph-container">
        <v-container fluid class="ph-nonex-container">
            <v-row class="ma-3">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="users"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            :search = "search"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-flex md4 class="search">
                                            <v-text-field
                                                v-model="search"
                                                placeholder="Search"
                                                label="Search"
                                                single-line
                                                hide-details
                                            ></v-text-field> 
                                        </v-flex>
                                    </v-layout>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="addUser(item.emailadd_company, item.name)"
                                        >
                                        <v-icon>mdi-account-plus</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog
                v-model="addUserDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title>
                        Confirmation
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <p style="font-size: 15px;">Add <b>{{selectedUser.name}}</b> to Pera Remit Partner System?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelDialog"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmAddUser"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="existingEmailDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title>
                        Existing Email
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <p style="font-size: 15px;"><b>{{selectedUser.name}}</b> is already in Pera Remit Partner System!</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="existingDialogConfirm"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

export default {
  name: 'Users',

  data () {
    return {
        search: '',
        users: [],
        existingEmail: [],
        selectedUser: '',
        addUserDialog: false,
        existingEmailDialog: false,
        headers: [
            {
                text: 'User Name',
                sortable: true,
                value: 'name'
            },
            {
                text: 'User Email',
                sortable: true,
                value: 'emailadd_company'
            },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                align: 'center'
            }
        ]
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'auth/currUser',
      hrisToken: 'auth/hrisToken',
    })
  },
  async mounted () {
        
        const res = await this.$store.dispatch('users/getAllHrisUsers', this.hrisToken)
        // this.users = res.data.body
        // console.log(res.data.body)

        res.data.body.map(item => {
            if (item.emailadd_company != null){
                this.users.push(item)
            }
        })

        if (this.addUserDialog == false){
            this.selectedUser = ''
        }

        const response = await this.$store.dispatch('users/doSearchUsers')
        response.data.result.map(item => {
            this.existingEmail.push(item.email)
        })
  },
  methods: {
    addUser(email, name){
        this.addUserDialog = true
        this.selectedUser = {
            name : name,
            email : email
        }
    },
    cancelDialog(){
        this.addUserDialog = false
        this.selectedUser = ''
    },
    existingDialogConfirm(){
        this.existingEmailDialog = false
        this.addUserDialog = false
        this.selectedUser = ''
    },
    async confirmAddUser(){
        console.log(this.selectedUser.name)
        console.log(this.selectedUser.email)

        const response = await this.$store.dispatch('users/doSearchUsers')
        response.data.result.map(item => {
            this.existingEmail.push(item.email)
        })

        if (this.existingEmail.includes(this.selectedUser.email)){
            this.addUserDialog = false
            this.existingEmailDialog = true
        } else {
            const payload = {
                name: this.selectedUser.name,
                email: this.selectedUser.email
            }
            const res = await this.$store.dispatch('users/addUsertoSam', payload)
            if (res.status === 200) {
                this.addUserDialog = false
                this.selectedUser = ''
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'User Successfully Added!','messagetype':'success'});
				// this.$router.push('/users')
			}
        }
    },
    formatDate (date) {
        return format(parseISO(date), 'yyyy-MM-dd')
    },
  }
}
</script>